import React from "react";
import { PageProps, Link } from "gatsby";
import { Page } from "../components/Page";
import SEO from "../components/Seo";
import { Header } from "../components/Header";
import { Container } from "../components/Container";

interface PageContext {
  tags: {
    path: string;
    label: string;
  }[];
}

const Template = ({ pageContext }: PageProps<any, PageContext>) => {
  return (
    <Page>
      <SEO title={"Tags"} />
      <Header />
      <Container>
        <h1>tags</h1>
        {pageContext.tags.map((tag) => (
          <h3 key={tag.label}>
            <Link to={tag.path}>{tag.label}</Link>
          </h3>
        ))}
      </Container>
    </Page>
  );
};
export default Template;
